@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat.core();

$md-primary: (
    50 : #fdf2e7,
    100 : #fadfc4,
    200 : #f6c99d,
    300 : #f2b375,
    400 : #f0a358,
    500 : #ed933a,
    600 : #eb8b34,
    700 : #e8802c,
    800 : #e57625,
    900 : #e06418,
    1000: #8c8c8c,
    1100: #fafafa,
    A100 : #ffffff,
    A200 : #ffeae0,
    A400 : #ffc9ad,
    A700 : #ffb893,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        1000: #000000,
        1100: #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-secondary: (
    50 : #fffbe8,
    100 : #fff5c5,
    200 : #ffef9f,
    300 : #ffe879,
    400 : #ffe35c,
    500 : #ffde3f,
    600 : #ffda39,
    700 : #ffd531,
    800 : #ffd129,
    900 : #eb8b34,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #fff2cc,
    A700 : #ffebb3,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-warn: (
    50 : #f9e0e0,
    100 : #f0b3b3,
    200 : #e68080,
    300 : #db4d4d,
    400 : #d42626,
    500 : #cc0000,
    600 : #c70000,
    700 : #c00000,
    800 : #b90000,
    900 : #ad0000,
    A100 : #ffd7d7,
    A200 : #ffa4a4,
    A400 : #ff7171,
    A700 : #ff5858,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$test-app-primary: mat.define-palette($md-primary, 500, 500, 500);
$test-app-accent: mat.define-palette($md-secondary, 900, 700, A500);
$test-app-warn: mat.define-palette($md-warn, 900, A400, A500);

$test-app-theme: mat.define-light-theme($test-app-primary, $test-app-accent, $test-app-warn);

@include mat.all-component-themes($test-app-theme);


.validation-div {
    color: mat.get-color-from-palette($test-app-warn);
}


.ddp-activity-validation {
    @extend .ddp-activity-validation;
    width: 100%;
    margin: 0;
}